import { asset } from "$fresh/runtime.ts";
import { useState } from "preact/hooks";

const NavLinks = ({ home }: { home?: boolean }) => (
   <>
      {home && <li><a href="/">HOME</a></li>}
      <li><a href="/about">ABOUT</a></li>
      <li><a href="/membership">MEMBERSHIP</a></li>
      <li><a href="/events">EVENTS</a></li>
      <li><a href="/history">HISTORY</a></li>
      <li><a href="/gallery">GALLERY</a></li>
      <li><a href="/resources">RESOURCES</a></li>
      <li><a href="/contact">CONTACT</a></li>
   </>
)

export function Navbar() {
   const [isNavOpen, setNavOpen] = useState(false);

   return (
      <header>
         <nav>
            <a href="/" className="flex items-center gap-2">
               <img src={asset("/media/mot_badge.webp")} alt="MOT" className="h-20" />
               <div className="flex flex-col gap-0.5">
                  <img src={asset("/media/logo.svg")} alt="NAACP" />
                  <span className="text-[#00437c] text-xs">Middletown Odessa and Townsend</span>
				  <span className="text-[#00437c] text-xs">DELAWARE BRANCH</span>
				  <span className="text-[#00437c] text-xs">Branch #21-AB Youth Council #27-AF</span>
               </div>
            </a>

            <ul className="font-black max-lg:hidden">
               <NavLinks />
            </ul>

            <button id="nav-toggle" className="px-8 lg:hidden" aria-controls="mobile-nav" aria-expanded={isNavOpen} aria-label="menu" onClick={() => setNavOpen(!isNavOpen)}>
               <div></div>
               <div></div>
               <div></div>
            </button>

            <ul id="mobile-nav" className={`flex-col text-lg items-center justify-center lg:hidden absolute top-20 right-0 left-0 bg-black *:py-4 overflow-visible z-10 ${isNavOpen ? "flex" : "hidden"}`}>
               <NavLinks home />
            </ul>
         </nav>
      </header>
   )
}